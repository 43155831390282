<template>
  <div class="supplier-questionnaire-form">
    <validation-observer ref="formObserver">
      <form ref="fullVacancyForm" class="form">
        <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="organizationName"
              type="text"
              placeholder=""
              class="form__input"
              name="organization"
            />
            <label for="organization"
              >{{ $t("organizationName") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div>
        <div class="form__group">
          <input
            v-model="serviceList"
            type="text"
            placeholder=""
            class="form__input"
            name="services"
          />
          <label for="services">{{ $t("listOfServices") }} </label>
        </div>
        <div class="form__group">
          <input
            v-model="productAvailability"
            type="text"
            class="form__input"
            name="availability"
          />
          <label for="availability">{{ $t("availibility") }} </label>
        </div>

        <div class="form__group">
          <input
            v-model="turnover"
            type="text"
            placeholder=""
            class="form__input"
            name="turnover"
          />
          <label for="turnover">{{ $t("tradeTurnover") }} </label>
        </div>
        <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="contactPerson"
              type="text"
              placeholder=""
              class="form__input"
              name="person"
            />
            <label for="person"
              >{{ $t("contact") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div>

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input v-model="name" type="text" name="name" />
            <label for="name"
              >{{ $t("name") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="email">
            <input v-model="email" type="text" class="form__input" />
            <label for="name"
              >{{ $t("email") }}
              <span style="color: red">{{
                errors[0] === "email"
                  ? $t("emailEnteredIncorrectly")
                  : errors[0]
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="deliveryPossibility"
              type="text"
              name="delivery-opportunity"
            />
            <label for="delivery-opportunity" class="long-label"
              >{{ $t("regionalVending") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="pricesAndConditions"
              type="text"
              placeholder=""
              class="form__input"
              name="prices"
            />
            <label for="prices" class="long-label"
              >{{ $t("otherPrices") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->
        <!-- 
        <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="competitiveAdvantages"
              type="text"
              placeholder=""
              class="form__input"
              name="description"
            />
            <label for="description" class="long-label"
              >{{ $t("advantagesDescription") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="address"
              type="text"
              placeholder=""
              class="form__input"
              name="address"
            />
            <label for="address"
              >{{ $t("address") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="phone"
              type="text"
              placeholder=""
              class="form__input"
              name="phone"
            />
            <label for="phone"
              >{{ $t("phone") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <input
            v-model="fax"
            type="text"
            placeholder=""
            class="form__input"
            name="fax"
          />
          <label for="fax">{{ $t("fax") }} </label>
        </div> -->

        <!-- <div class="form__group">
          <input
            v-model="web"
            type="text"
            placeholder=""
            class="form__input"
            name="website"
          />
          <label for="website">{{ $t("webPage") }}</label>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="contactPersonPosition"
              type="text"
              placeholder=""
              class="form__input"
              name="position"
            />
            <label for="position"
              >{{ $t("position") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="since"
              type="number"
              placeholder=""
              class="form__input"
              name="year"
            />
            <label for="year"
              >{{ $t("companyAge") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="quantity"
              type="number"
              placeholder=""
              class="form__input"
              name="kinds"
            />
            <label for="kinds" class="long-label"
              >{{ $t("typeAmmount") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="mainClients"
              type="text"
              placeholder=""
              class="form__input"
              name="main-clients"
            />
            <label for="main-clients"
              >{{ $t("mainClients") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <div class="form__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="promoPlan"
              type="text"
              placeholder=""
              class="form__input"
              name="promo-plan"
            />
            <label for="promo-plan" class="long-label"
              >{{ $t("promoPlan") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("fillForm") : ""
              }}</span></label
            >
          </validation-provider>
        </div> -->

        <!-- <p v-if="companyTypeUnselected" style="color: red">
          {{ $t("notSelected") }}
        </p>
        <app-select
          v-model="companyType"
          :placeholder="$t('companyIsTitle')"
          :dropdown="company"
          field="name"
          :translate="true"
          :sent="isSent"
        />

        <p v-if="qualityTypeUnselected" style="color: red">
          {{ $t("notSelected") }}
        </p>
        <app-select
          v-model="qualityType"
          :placeholder="$t('quality')"
          :dropdown="quality"
          field="name"
          :sent="isSent"
        />

        <p v-if="directionTypeUnselected" style="color: red">
          {{ $t("notSelected") }}
        </p>
        <app-select
          v-model="directionType"
          :placeholder="$t('type')"
          :dropdown="direction"
          field="name"
          :sent="isSent"
        />

        <p v-if="cityUnselected" style="color: red">{{ $t("notSelected") }}</p>
        <app-select
          v-model="city"
          :placeholder="$t('sellingCity')"
          :dropdown="cities"
          :attributes="true"
          field="name"
          :sent="isSent"
        /> -->

        <button class="btn-form" @click.prevent="sendForm">
          {{ $t("submit") }}
        </button>
      </form>
    </validation-observer>
    <app-modal-received
      v-if="isShowModalReceived"
      @close="closeModalReceived"
      title="formAccepted"
      text="formAcceptedText"
      image="parachute.svg"
      btn="formAcceptedNice"
    />
  </div>
</template>

<script>
import { getAllCities } from "@/api/city";
import { sendSupplierQuestionnaire } from "@/api/supplier-questionnaire";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "SupplierQuestionnaireForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    // AppSelect: () => import("@/components/common/AppSelect"),
    AppModalReceived: () =>
      import("@/components/pages/modal/AppModalReceived.vue"),
  },
  data() {
    return {
      // selects
      cities: [],
      company: [
        {
          name: "manufacturer",
          type: "manufacturer",
        },
        {
          name: "distributor",
          type: "distributor",
        },
        {
          name: "manufacturerDistributor",
          type: "both",
        },
      ],

      quality: [
        {
          name: "Эконом",
          type: "econom",
        },
        {
          name: "Стандарт",
          type: "standard",
        },
        {
          name: "Low premium",
          type: "low_premium",
        },
        {
          name: "Premium",
          type: "premium",
        },
      ],

      direction: [
        {
          name: "Food",
          type: "food",
        },
        {
          name: "Fresh",
          type: "fresh",
        },
        {
          name: "Non food",
          type: "non",
        },
      ],

      // form variables

      name: "",
      email: "",
      deliveryPossibility: "",
      productAvailability: "",
      serviceList: "",
      pricesAndConditions: "",
      competitiveAdvantages: "",
      organizationName: "",
      address: "",
      phone: "",
      fax: "",
      web: "",
      contactPerson: "",
      contactPersonPosition: "",
      since: "",
      turnover: "",
      quantity: "",
      mainClients: "",
      promoPlan: "",

      // select variables

      companyType: null,
      qualityType: null,
      directionType: null,
      city: null,

      // unselected

      companyTypeUnselected: false,
      qualityTypeUnselected: false,
      directionTypeUnselected: false,
      cityUnselected: false,

      // form data
      questionnaireForm: null,

      isSent: false,

      isShowModalReceived: false,
    };
  },
  created() {
    getAllCities().then((cities) => {
      this.cities = cities;
    });
  },
  watch: {
    companyType: {
      handler() {
        if (this.companyType) {
          this.isSent = false;
          this.companyTypeUnselected = false;
        }
      },
    },
    qualityType: {
      handler() {
        if (this.qualityType) {
          this.qualityTypeUnselected = false;
        }
      },
    },
    directionType: {
      handler() {
        if (this.directionType) {
          this.directionTypeUnselected = false;
        }
      },
    },
    city: {
      handler() {
        if (this.city) {
          this.cityUnselected = false;
        }
      },
    },
  },
  methods: {
    sendForm() {
      this.$refs.formObserver.validate().then((success) => {
        // this.checkSelects();
        if (success) {
          this.questionnaireForm = {
            data: {
              // name: this.name,
              // email: this.email,
              // regional_develivery: this.deliveryPossibility,
              assortiment: this.productAvailability,
              products: this.productAvailability,
              services: this.serviceList,
              // prices: this.pricesAndConditions,
              // advantages: this.competitiveAdvantages,

              company: this.organizationName,

              // company_address: this.address,
              // phone: this.phone,
              // fax: this.fax,
              // web: this.web,
              contact_person: this.contactPerson,
              // contact_person_position: this.contactPersonPosition,
              // since: this.since,
              turnover: this.turnover,
              // quantity: this.quantity,
              // main_clients: this.mainClients,
              // promo_plan: this.promoPlan,
              // company_type: this.companyType.type,
              // quality: this.qualityType.type,
              // direction: this.directionType.type,
              // cities: this.city.id,
              // createdAt: "2022-06-07T10:57:07.680Z",
              // updatedAt: "2022-06-07T10:57:07.680Z",
              // publishedAt: "2022-06-07T10:57:07.680Z",
              // createdBy: "string or id",
              // updatedBy: "string or id",
            },
          };
          sendSupplierQuestionnaire(this.questionnaireForm).then((res) => {
            if (res.status === 200) {
              // this.resetForm();
              this.isShowModalReceived = true;
              // location.reload();
              // window.scrollTo({ top: 0, behavior: "smooth" });
            }
          });
        } else {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      });
    },

    checkSelects() {
      if (!this.companyType) {
        this.companyTypeUnselected = true;
      }
      if (!this.qualityType) {
        this.qualityTypeUnselected = true;
      }
      if (!this.directionType) {
        this.directionTypeUnselected = true;
      }
      if (!this.city) {
        this.cityUnselected = true;
      }

      if (
        this.companyType &&
        this.qualityType &&
        this.directionType &&
        this.city
      ) {
        return true;
      } else {
        return false;
      }
    },
    resetForm() {
      this.$refs.fullVacancyForm.reset();
      // this.name = "";
      // this.email = "";
      // this.deliveryPossibility = "";
      // this.productAvailability = "";
      // this.serviceList = "";
      // this.pricesAndConditions = "";
      // this.competitiveAdvantages = "";
      // this.organizationName = "";
      // this.address = "";
      // this.phone = "";
      // this.contactPerson = "";
      // this.contactPersonPosition = "";
      // this.since = "";
      // this.turnover = "";
      // this.quantity = "";
      // this.mainClients = "";
      // this.promoPlan = "";

      this.isSent = true;
      this.fax = null;
      this.web = null;
      this.companyType = null;
      this.qualityType = null;
      this.directionType = null;
      this.city = null;
    },

    closeModalReceived() {
      this.isShowModalReceived = false;
      location.reload();
    },
  },
};
</script>
